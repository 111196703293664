import React from "react"
import "./header.css"
import ME from "../../assets/me.png"
import { CTA } from "./CTA"
import { HeaderSocials } from "./HeaderSocials"
import Typed from "react-typed"

export const Header = () => {
  return (
    <header id="home">
      <div className="container header__container">
        <h4>Hello I'm</h4>
        <h1>Walter Alturo</h1>
        <h4>
          And I'm a{" "}
          <span className="typing">
            <Typed
              strings={[
                "Developer",
                "Freelancer",
                "Game Desinger",
                "Support",
                "Master",
              ]}
              typeSpeed={100}
              backSpeed={50}
              loop
            />
          </span>
        </h4>

        {/* <h5 className="text-light">FullStack Developer</h5> */}
        <CTA />
        <HeaderSocials />
        <div className="me">
          <img src={ME} alt="me" />
        </div>
        <a href="#contact" className="scroll__down">
          Scroll Down
        </a>
      </div>
    </header>
  )
}
