import React from "react"
import "./services.css"
import { BiCheck, BiGame } from "react-icons/bi"
import { MdOutlineWeb } from "react-icons/md"
import { TbDeviceMobile } from "react-icons/tb"

export const Services = () => {
  return (
    <section id="services">
      {/* <h5>What I Offer</h5> */}
      <h2 className="title">Services</h2>

      <div className="container services__container">
        {/* GAME DESIGN */}

        <article className="service">
          <div className="service__head">
            <BiGame className="service__head-icon" />
            <h3>Game Design</h3>
          </div>
          <div></div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Design of game mechanics and game rules.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Character development and narrative.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Design of levels and missions of the game with unity.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Creation of conceptual art and visual design.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Quality and playability tests.</p>
            </li>
          </ul>
        </article>
        {/* WEB DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <MdOutlineWeb className="service__head-icon" />
            <h3>Web Development</h3>
          </div>
          <div></div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Development of responsive and optimized websites for search
                engines.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Integration of content management systems (CMS) such as
                WordPress and Joomla.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Implementation of e-commerce solutions for online stores.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Design and development of custom web applications.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Maintenance and updating of existing websites.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Design and development of chatbots systems and virtual
                assistants to improve customer service.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Data analysis and reporting to improve efficiency and decision
                making.
              </p>
            </li>
          </ul>
        </article>
        {/* CLOUD DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <TbDeviceMobile className="service__head-icon" />
            <h3>Mobile Development</h3>
          </div>
          <div></div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Design and development of custom mobile applications for iOS and
                Android.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Integration of mobile applications with existing systems in a
                company.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Implementation of mobile security solutions to protect the
                privacy of users.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                User interface (UI) and user experience (UX) design for mobile
                applications.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Quality testing and optimization of mobile applications.</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}
