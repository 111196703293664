import React from "react"
import "./portfolio.css"
import IMG1 from "../../assets/portfolio1.png"
import IMG2 from "../../assets/portfolio2.png"
import IMG3 from "../../assets/portfolio3.png"
// import IMG4 from "../../assets/portfolio4.jpg"
// import IMG5 from "../../assets/portfolio5.png"
import IMG6 from "../../assets/portfolio6.png"

// const data = []

export const Portfolio = () => {
  return (
    <section id="portfolio">
      {/* <h5>My Recent Work</h5> */}
      <h2 className="title">Portfolio</h2>
      {/* {data.map(({ id, image, title, github, demo }) => {
        return (
          <article key={id} className="portfolio__item">
            <div className="portfolio__item-image">
              <img src={image} alt={title} />
            </div>
            <h3>{title}</h3>
            <div className="portfolio__item-cta">
              <a href={github} className="btn" target="_blank" rel="noreferrer">
                Github
              </a>
              <a
                href={demo}
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                Live Demo
              </a>
            </div>
          </article>
        )
      })} */}
      <div className="container portfolio__container">
        
        {/* <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG4} alt="Portfolio 1" />
          </div>
          <h3>This is a portfolio item title</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              Github
            </a>
            <a
              href="https://github.com"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG5} alt="Portfolio 1" />
          </div>
          <h3>This is a portfolio item title</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              Github
            </a>
            <a
              href="https://github.com"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article> */}
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG6} alt="Portfolio 1" />
          </div>
          <h3>BDAPON</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/WalterAlturoaAparicio/placa-app"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              Github
            </a>
            {/* <a
              href="https://github.com/WalterAlturoaAparicio/placa-app"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a> */}
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG2} alt="Portfolio 1" />
          </div>
          <h3>Topo App</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/WalterAlturoaAparicio/topo_app"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              Github
            </a>
            {/* <a
              href="https://github.com/WalterAlturoaAparicio/topo_app"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a> */}
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG3} alt="Portfolio 1" />
          </div>
          <h3>Seize-info</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/WalterAlturoaAparicio/seize-info"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              Github
            </a>
            <a
              href="https://seize-info.nadirsas.com/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG1} alt="Portfolio 6" />
          </div>
          <h3>Heart With Imagination - Tupoy</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/WalterAlturoaAparicio/tupoy.github.io"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              Github
            </a>
            <a
              href="https://walteralturoaaparicio.github.io/tupoy.github.io/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article>
      </div>
    </section>
  )
}
