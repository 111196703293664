import React from "react"
import { BsLinkedin } from "react-icons/bs"
import { FaGithub } from "react-icons/fa"
import { AiFillInstagram } from "react-icons/ai"

export const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/walter-alturo-2369ab160/" target="_blank" rel="noreferrer">
        <BsLinkedin />
      </a>
      <a href="https://github.com/WalterAlturoaAparicio" target="_blank" rel="noreferrer">
        <FaGithub />
      </a>
      <a href="https://www.instagram.com/walter_athos/" target="_blank" rel="noreferrer">
        <AiFillInstagram />
      </a>
    </div>
  )
}
