import React from "react"
import "./about.css"
import ME from "../../assets/me-about.jpg"
import { FaAward } from "react-icons/fa"
import { FiUsers } from "react-icons/fi"
import { VscFolderLibrary } from "react-icons/vsc"

export const About = () => {
  return (
    <section id="about">
      {/* <h5>Get To Know</h5> */}
      <h2 className="title">About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="Walter Alturo's About" />
          </div>
        </div>
        <div className="about__contet">
          <div className="about__cards">
            <article className="about__card">
              <FaAward />
              <h5>Experience</h5>
              <small>3+ Years Working</small>
            </article>

            <article className="about__card">
              <FiUsers />
              <h5>Clients</h5>
              <small>30+ Worldwide</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary />
              <h5>Projects</h5>
              <small>20+ Completed</small>
            </article>
          </div>
          <p>
            IT professional with over 3 years of experience in systems
            architecture and software development, in many market segments, on
            various platforms (Web, Linux, Azure), working with different
            methodologies (RUP, SCRUM). I am a versatile professional, able to
            work with different and diverse technologies, roles, and projects. I
            believe in the power of a good mood to transform any situation and
            help the team achieve its goals.  
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  )
}
