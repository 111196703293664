import React from "react"
import "./experience.css"
import { ImHtmlFive } from "react-icons/im"
import {
  FaCss3Alt,
  FaBootstrap,
  FaPython,
  FaJava,
  FaUnity,
  FaAws,
} from "react-icons/fa"
import { DiJavascript1, DiMongodb } from "react-icons/di"
import { RiReactjsLine } from "react-icons/ri"
import { GrNode, GrMysql } from "react-icons/gr"
import { SiTypescript, SiAngular, SiFlutter } from "react-icons/si"

export const Experience = () => {
  return (
    <section id="experience">
      {/* <h5>What Skills I Have</h5> */}
      <h2 className="title">My Experience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <ImHtmlFive className="experience__details-icon" />
              <div>
                <h4>HTML</h4>
                <div className="progress-bar-90"></div>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <DiJavascript1 className="experience__details-icon" />
              <div>
                <h4>JavaScipt</h4>
                <div className="progress-bar-95"></div>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <RiReactjsLine className="experience__details-icon" />
              <div>
                <h4>React</h4>
                <div className="progress-bar-70"></div>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCss3Alt className="experience__details-icon" />
              <div>
                <h4>CSS</h4>
                <div className="progress-bar-70"></div>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <SiFlutter className="experience__details-icon" />
              <div>
                <h4>Flutter</h4>
                <div className="progress-bar-60"></div>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaBootstrap className="experience__details-icon" />
              <div>
                <h4>Bootstrap</h4>
                <div className="progress-bar-80"></div>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiAngular className="experience__details-icon" />
              <div>
                <h4>Angular</h4>
                <div className="progress-bar-60"></div>
                <small className="text-light">Basic</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <GrNode className="experience__details-icon" />
              <div>
                <h4>Node JS</h4>
                <div className="progress-bar-95"></div>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <GrMysql className="experience__details-icon" />
              <div>
                <h4>MySQL</h4>
                <div className="progress-bar-80"></div>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiTypescript className="experience__details-icon" />
              <div>
                <h4>Typescript</h4>
                <div className="progress-bar-80"></div>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <DiMongodb className="experience__details-icon" />
              <div>
                <h4>MongoDB</h4>
                <div className="progress-bar-60"></div>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaJava className="experience__details-icon" />
              <div>
                <h4>Java</h4>
                <div className="progress-bar-70"></div>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaPython className="experience__details-icon" />
              <div>
                <h4>Python</h4>
                <div className="progress-bar-60"></div>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaUnity className="experience__details-icon" />
              <div>
                <h4>Unity</h4>
                <div className="progress-bar-70"></div>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaAws className="experience__details-icon" />
              <div>
                <h4>Aws</h4>
                <div className="progress-bar-60"></div>
                <small className="text-light">Basic</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}
