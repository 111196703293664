import React, { useEffect, useState } from "react"
import "./nav.css"
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai"
import { BiBook, BiMessageSquareDetail } from "react-icons/bi"
import { RiServiceLine } from "react-icons/ri"

export const Nav = () => {
  const [activeNav, setActiveNav] = useState("#home")
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      const aboutY = document.getElementById("about").getBoundingClientRect().y
      const experienceY = document
        .getElementById("experience")
        .getBoundingClientRect().y
      const servicesY = document
        .getElementById("services")
        .getBoundingClientRect().y
      const contactY = document
        .getElementById("contact")
        .getBoundingClientRect().y
      if (currentScrollY < 100) setActiveNav("#home")
      if (aboutY < 100) setActiveNav("#about")
      if (experienceY < 100) setActiveNav("#experience")
      if (servicesY < 100) setActiveNav("#services")
      if (contactY < 100) setActiveNav("#contact")
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => window.removeEventListener("scroll", handleScroll)
  }, [activeNav])
  return (
    <nav>
      <a
        href="#home"
        className={activeNav === "#home" ? "active" : ""}
        onClick={() => setActiveNav("#home")}
      >
        <AiOutlineHome />
      </a>
      <a
        href="#about"
        className={activeNav === "#about" ? "active" : ""}
        onClick={() => setActiveNav("#about")}
      >
        <AiOutlineUser />
      </a>
      <a
        href="#experience"
        className={activeNav === "#experience" ? "active" : ""}
        onClick={() => setActiveNav("#experience")}
      >
        <BiBook />
      </a>
      <a
        href="#services"
        className={activeNav === "#services" ? "active" : ""}
        onClick={() => setActiveNav("#services")}
      >
        <RiServiceLine />
      </a>
      <a
        href="#contact"
        className={activeNav === "#contact" ? "active" : ""}
        onClick={() => setActiveNav("#contact")}
      >
        <BiMessageSquareDetail />
      </a>
    </nav>
  )
}
